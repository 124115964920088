<template>
  <div>
    <h2>活动订单确认页面</h2>
    {{ activityOrder.orderNum }}<br />
    {{ activityOrder.activityTitle }}<br />
    {{ activityOrder.buyerOpenId }}<br />
    {{ activityOrder.consumeMoney }}<br />
    {{ activityOrder.orderStatus }}<br />
    {{ activityOrder.createTime }}<br />
    <van-button type="info" @click="payIt">支付</van-button>
  </div>
</template>
<script>
import wxconfig from '@/wx'
import orderApi from '@/api/order'
export default {
  data() {
    return {
      activityOrder: {}
    }
  },
  mounted() {
    this.initOrder()
    this.initWxConfig()
  },
  methods: {
    payIt() {
      orderApi.beforePay(this.activityOrder.orderNum).then(res => {
        if (res.succ) {
          let data = res.data
          this.$wx.chooseWXPay({
            timestamp: data.timeStamp,
            nonceStr: data.nonceStr,
            package: data.package,
            signType: data.signType,
            paySign: data.paySign,
            success: res => {
              // 支付成功后跳转
              this.$router.replace({ path: '/' })
            },
            cancel: res => {
              // 用户取消支付
              console.log('用户取消：' + JSON.stringify(res))
            },
            fail(error) {
              console.log('支付报错!', JSON.stringify(error))
            }
          })
        }
      })
    },
    initOrder() {
      let orderNum = this.$route.params.orderNum
      orderApi.getByOrderNum(orderNum).then(res => {
        if (res.succ) {
          this.activityOrder = res.data.item
        }
      })
    },
    initWxConfig() {
      // 配置微信信息
      wxconfig()
    }
  }
}
</script>
